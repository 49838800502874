@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url('https://fonts.googleapis.com/css?family=Questrial');
body {
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFF;
}
html{
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(217,107,30, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler:focus{
  outline: none;
}
.navbar{
  padding-top:2rem;
  width: 100%;
}
.index{
  background: url(Assets/index-background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.aboutus{
  background: url(Assets/aboutus-background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.gallery{
  background: url(Assets/gallery-background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.services{
  background: url(Assets/services-background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}
.showroom{
  background: url(Assets/showroom-background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}
.contact{
  background: url(Assets/contact-background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}
.page-title{
  font-family: 'Questrial', sans-serif;
  font-weight: 700;
}
.page-sub-title{
  color: #D96B1E;
  font-weight: 700;
  font-size: 1.2rem;
}
.footer{
  background-color: #F7D57F;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  color: black;
}
.navbar-nav .nav-item{
  padding: .1rem 1.38rem .1rem 1.38rem;
  background-color: lightgrey;
  color: grey;
  margin: .1rem;
}
.navbar-nav .nav-item:hover{
  background-color: #F7D57F;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}
.navbar-nav a{
  color: grey;
  text-decoration: none;
  display: block;
}
.index-text h1{
  font-size: 4rem;
}
.index-welcome{
  color: #B35F24;
  font-style: italic;
}
.index-jackson{
  color: white;
}
.aboutus-content, .services-content, .gallery-content, .contact-content{
  background-color: white;
  color: black;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  min-height: calc(100% - 15rem);
  position: absolute;
  width: 100%;
  font-family: 'Questrial', sans-serif;
  padding-bottom: 5rem;
}
.card-header, .card-footer{
  background-color: transparent;
}
.card{
  border: none;
}
.card-deck {
  display: grid;
}
@media (min-width: 0) {
.card-deck {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 992px) {
.card-deck {
    grid-template-columns: repeat(3, 1fr);
  }
}
.tile{
  margin-left: auto;
  margin-right: auto;
}
.send-button{
  background-color: black;
}
.black-link, .black-link:hover, .black-link:active, .black-link:link{
  color: black;
}